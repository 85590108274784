import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import ErrorBoundary from "../../../error-boundary";
import IconChevronDown from "@assets/icons/icon-chevron-down.svg";

/**
 * Recursive menu component that calls itself inside again if there
 * are subitems.
 * The level variable will count up to identify the nested layers.
 */
const RecursiveMenu = ({ items, level = 1, itemIcon }) => (
  <ul className={`level-${level}`}>
    {items.map((item, i) => (
      <li key={i}>
        {!!item.links?.length && (
          <span className="icon-has-children">
            <IconChevronDown />
          </span>
        )}
        <Link to={item.url.path} title={item.description || item.label}>
          {itemIcon} {item.label}
        </Link>
        {/* Recursively call menu component again if there are subitems */}
        {!!item.links?.length && (
          <ErrorBoundary>
            {/* <RecursiveMenu items={item.links} level={level + 1} /> */}
          </ErrorBoundary>
        )}
      </li>
    ))}
  </ul>
);

RecursiveMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
      links: PropTypes.array,
    })
  ).isRequired,
  level: PropTypes.number,
  itemIcon: PropTypes.element,
};

export default RecursiveMenu;
