import React from "react";
import LogoApotheke from "@assets/logo-apotheke.svg";

const ButtonApotheke = () => {
  return (
    <a className="btn btn-light">
      <LogoApotheke />
    </a>
  );
};

export default ButtonApotheke;
