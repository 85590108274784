import React, { useCallback, useEffect } from "react";

// Router
import { Outlet, useLocation } from "react-router-dom";

// Redux
import { useSelector } from "react-redux";

// i18n
import { IntlProvider } from "react-intl";
import messages_de from "./i18n/translations/de.json";
import messages_en from "./i18n/translations/en.json";

// Page Components
import PageFooter from "./footer/page-footer";
import ErrorBoundary from "./error-boundary";
import Header from "./header/header";
import flattenMessages from "@lib/flatten-messages";

export const defaultLocale = "de",
  defaultMessages = messages_de;

const messages = {
  und: defaultMessages,
  de: messages_de,
  en: messages_en,
};

/**
 * @todo 404.
 */
const AppLayout = () => {
  const location = useLocation();
  const currentLanguage = useSelector((state) => state.i18n.currentLanguage);

  /**
   * Generate Base Class names for main wrapper.
   * @see https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#what-about-memoization
   *
   * @param {string} pathname - Current Path
   * @returns {string} - Base Classes
   */
  const generateMainClasses = useCallback((pathname) => {
    const pathNameSplitted = pathname.split("/");

    // Remove first (empty) item
    pathNameSplitted.shift();

    return `${pathNameSplitted[0]} ${pathNameSplitted
      .splice(1, pathNameSplitted.length)
      .join("-")}`;
  }, []);

  useEffect(() => {
    document.body.className += `${generateMainClasses(location.pathname)}`;
  }, [generateMainClasses, location.pathname]);

  useEffect(() => {
    // Scroll to top on route change
    if (!location.hash) {
      window.scrollTo(0, 0);
      document.body.className = `${generateMainClasses(location.pathname)}`;
    }
  }, [generateMainClasses, location]);

  return (
    <IntlProvider
      locale={currentLanguage !== "und" ? currentLanguage : defaultLocale}
      defaultLocale={defaultLocale}
      messages={flattenMessages(messages[currentLanguage])}
    >
      <div className="main-content">
        <ErrorBoundary>
          <Header />
        </ErrorBoundary>

        <main className="main-page-content">
          <ErrorBoundary>
            {/**
             * Router was replaced by createStaticRouter (React Router v6).
             * Routes are now defined in the routes.js file. The <Outlet> component is
             * used to render the matched child route. This unlocks data routes features.
             * @see https://reactrouter.com/en/main/guides/ssr
             */}
            <Outlet />
          </ErrorBoundary>
        </main>

        <ErrorBoundary>
          <PageFooter />
        </ErrorBoundary>
      </div>
    </IntlProvider>
  );
};

AppLayout.propTypes = {};

export default AppLayout;
