import React, { useState } from "react";
import PropTypes from "prop-types";
import { Menu } from "../general-components/navigation/menu";
import classNames from "classnames";

/**
 * Close on menu link click.
 */
const DesktopNavigation = () => {
  return (
    <Menu
      menuName="main"
      navClassName="col main-menu-desktop text-m d-none d-md-block"
    />
  );
};

const SingleLinkPropType = PropTypes.shape({
  description: PropTypes.string,
  label: PropTypes.string.isRequired,
  url: PropTypes.shape({
    __typename: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  }).isRequired,
});

const LinkPropType = PropTypes.shape({
  link: SingleLinkPropType,
  subtree: PropTypes.arrayOf(
    PropTypes.shape({
      link: SingleLinkPropType,
      subtree: PropTypes.arrayOf(
        PropTypes.shape({
          link: SingleLinkPropType,
          /*TODO: Recursive? */
          subtree: PropTypes.array,
        })
      ).isRequired,
    })
  ).isRequired,
});

DesktopNavigation.propTypes = {
  showSearch: PropTypes.bool,
  mainMenuQuery: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    menuByName: PropTypes.shape({
      label: PropTypes.string.isRequired,
      links: PropTypes.arrayOf(LinkPropType).isRequired,
    }),
  }),
};

export default DesktopNavigation;
