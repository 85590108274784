import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import PropTypes from "prop-types";

import menuQuery from "./menu-query.graphql";
import RecursiveMenu from "./components/recursive-menu";
import classNames from "classnames";
import ErrorBoundary from "../../error-boundary";

export const Menu = ({
  menuTree,
  menuName,
  showLabel = false,
  itemIcon,
  navClassName = "",
  children,
}) => {
  const { data, loading = false, error = false } = useQuery(menuQuery, {
    variables: { name: menuName },
    skip: menuTree?.links.length || !menuName,
  });

  const tree = menuTree || data?.menuByName;

  if (loading) {
    return "Loading...";
  }

  if (error) {
    return `Error! ${error.message}`;
  }

  if (!tree?.links?.length) {
    return;
  }

  if (children?.length) {
    return <>{children({ tree })}</>
  }

  return (
    <>
      {showLabel && <h2>{tree.label}</h2>}
      <nav
        className={classNames({
          [tree.id]: tree.id,
          [navClassName]: !!navClassName,
        })}
      >
        <ErrorBoundary>
          <RecursiveMenu items={tree.links} itemIcon={itemIcon} />
        </ErrorBoundary>
      </nav>
    </>
  );
};

const menuItemPropTypes = {
  label: PropTypes.string,
  url: PropTypes.shape({
    path: PropTypes.string,
  }),
  links: PropTypes.arrayOf(PropTypes.shape(menuItemPropTypes)),
};

Menu.propTypes = {
  menuTree: PropTypes.shape({
    label: PropTypes.string,
    links: PropTypes.arrayOf(PropTypes.shape(menuItemPropTypes)),
  }),
  menuName: PropTypes.string,
  showLabel: PropTypes.bool,
  itemOnClickHandler: PropTypes.func,
  itemClassName: PropTypes.string,
  itemIcon: PropTypes.element,
  listClassName: PropTypes.string,
  navClassName: PropTypes.string,
  children: PropTypes.func,
};
