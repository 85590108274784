import React, { Component } from "react";
import { Link } from "react-router-dom";
import Logo from "@assets/Logo_GEDISA.svg"

class LogoButton extends Component {
  render() {
    return (
      <Link className="site-logo-button" to="/">
        <Logo />
      </Link>
    );
  }
}

LogoButton.propTypes = {};

export default LogoButton;
