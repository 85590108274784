import React, { Component } from "react";
import { Oval } from "react-loader-spinner";
import LogoButton from "./logo-button";

class LoadingIndicator extends Component {
  render() {
    return (
      <div className={`loading-indicator-wrap ${this.props.fullPage ? 'fullpage' : ''}`}>
        {this.props.fullPage ? (
          <LogoButton />
        ) : (
          <Oval
            color="var(--c-theme-1)"
            secondaryColor="var(--c-grey-20)"
            height={50}
            width={50}
            timeout={50000}
            strokeWidth={4}
          />
        )}
      </div>
    );
  }
}

export default LoadingIndicator;
