import { useEffect, useState } from "react";

/**
 * Custom hook that returns scroll positions and directions as state
 */
const useScrollInfo = (callback) => {
  const [scrollInfo, setScrollInfo] = useState({});

  useEffect(() => {
    const scrollHandler = () => {
      setScrollInfo((prev) => {
        const info = {
          x: window.scrollX,
          y: window.scrollY,
          xDir: window.scrollX - (prev.x || 0),
          yDir: window.scrollY - (prev.y || 0),
        };
        callback && callback(info);
        return info;
      });
    };

    // run initially on load
    scrollHandler();

    // attach the scroll listener
    window.addEventListener("scroll", scrollHandler);

    // clean up
    return () => window.removeEventListener("scroll", scrollHandler);
  }, []);

  return scrollInfo;
};

export default useScrollInfo;
