import React from "react";
import LoadingIndicator from "../general-components/loading-indicator";
import { SocialMedia } from "./components/social-media";
import { Menu } from "../general-components/navigation/menu";
import useConfigQuery from "../hooks/use-config-query/use-config-query";
import Error from "../system/error";
import moment from "moment";
import LogoButton from "@general-components/logo-button";
import IconSupport from "@assets/icons/icon-support.svg";

const PageFooter = () => {
  const {
    data: footerData,
    loading,
    error,
  } = useConfigQuery("footer_social_media");

  if (loading) {
    return <LoadingIndicator />;
  }
  if (error) {
    return <Error error={error} />;
  }

  if (!footerData)
    return (
      <div className="container">
        <p>
          No footer data. Create footer and social media config pages in Drupal.
        </p>
      </div>
    );

  /**
   * The footer and social_media configs are requested at the same time so
   * they will be stored in an array called "items" (see useConfigQuery).
   */
  const footerConfig = footerData;

  return (
    <footer id="page-footer">
      <div className="container-fluid text-s">
        {footerConfig && (
          <>
            <div className="row">
              <div className="col-16 col-lg-13">
                <p className="copyright">
                  {footerConfig.fieldCopyright}
                  <br />
                  <span>&copy; {moment().format("YYYY")}</span>
                  <br />
                  <a
                    href={`mailto:${footerConfig.fieldEmail}`}
                    className="email"
                  >
                    {footerConfig.fieldEmail}
                  </a>
                </p>
              </div>

              <div className="col-16 col-lg-3">
                <LogoButton />
              </div>
            </div>

            <div className="row">
              <div className="col-16 order-2 order-lg-1 col-lg-13 d-flex flex-column flex-lg-row align-items-lg-center">
                {!!footerConfig.fieldLegalMenu && (
                  <Menu
                    menuName={footerConfig.fieldLegalMenu.targetId}
                    navClassName="legal-menu"
                  />
                )}
                <a className="btn btn-light btn-sm btn-icon">
                  <IconSupport />
                  Support für Apotheken
                </a>
              </div>
              <div className="col-16 col-lg-3 order-1 order-lg-2">
                <SocialMedia content={footerConfig} />
              </div>
            </div>
          </>
        )}
      </div>
    </footer>
  );
};

PageFooter.propTypes = {};

export default PageFooter;
