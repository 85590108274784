import { useQuery } from "@apollo/client";
import configQuery from "./config-query.graphql";

/**
 * Uses the useQuery hook to explicitly get a config page by name.
 * Returns the reduced data as an object.
 * @param {string|array} name Config page machine name.
 * @returns {object} Returns the reduced data of a useQuery hook.
 */
const useConfigQuery = (name) => {
  const { data, loading, error } = useQuery(configQuery, {
    variables: {
      // pass name of config as array
      name: typeof name === "string" ? [name] : name,
    },
  });

  let result;

  if (data?.configPagesQuery.entities.length > 1) {
    result = data?.configPagesQuery;
  } else {
    result = data?.configPagesQuery.entities[0]
  }

  return { data: result, loading, error };
};

export default useConfigQuery;
