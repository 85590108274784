import React, { useState } from "react";
import classNames from "classnames";
import { Menu } from "@general-components/navigation/menu";
import { AnimatePresence, motion } from "motion/react";
import { ChevronDown } from "@carbon/icons-react";
import { Link } from "react-router-dom";

const MobileNavigation = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleFullScreenMenu = () => {
    setMenuOpen((prev) => {
      if (prev) {
        document.body.classList.remove("fullscreen-menu-open");
      } else {
        document.body.classList.add("fullscreen-menu-open");
      }
      return !prev;
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" || event.key === "Escape") {
      toggleFullScreenMenu();
    }
  };

  return (
    <>
      <div className="d-flex align-items-center gap-3 d-block d-lg-none">
        <div
          className={classNames({
            "toggle-fullscreen-menu": true,
            "d-flex d-md-none": true,
            active: menuOpen,
          })}
          tabIndex={0}
          aria-label="toggle-full-screen-menu"
          onClick={toggleFullScreenMenu}
          onKeyDown={handleKeyDown}
        >
          <svg className="hamburger-menu" viewBox="0 0 24 24">
            <line className="hamburger-line" x1="2" y1="6" x2="22" y2="6" />
            <line className="hamburger-line" x1="2" y1="12" x2="22" y2="12" />
            <line className="hamburger-line" x1="2" y1="18" x2="22" y2="18" />
          </svg>
        </div>
      </div>
      <AnimatePresence>
        {menuOpen && (
          <motion.div
            id="fullscreen-menu"
            initial={{ x: "100%" }}
            animate={{ x: 0 }}
            exit={{ x: "100%" }}
            transition={{ ease: "easeInOut", duration: 0.3 }}
          >
            <div className="nav-wrap">
              <div className="container">
                <Menu menuName="main">
                  {({ tree }) => (
                    <nav>
                      <RecursiveMobileNavigation
                        links={tree?.links}
                        onClick={toggleFullScreenMenu}
                      />
                    </nav>
                  )}
                </Menu>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

const RecursiveMobileNavigation = ({ links, onClick, onGoBack, level = 0 }) => {
  const [subTree, setSubTree] = useState([]);

  return (
    <>
      <motion.ul
        className={`level-${level}`}
        initial={{ x: "100%" }}
        animate={{ x: 0 }}
        exit={{ x: "100%" }}
        transition={{ ease: "easeInOut", duration: 0.3 }}
      >
        {onGoBack && (
          <li className="back" onClick={onGoBack}>
            Zurück
          </li>
        )}
        {!!links?.length &&
          links.map((item, i) => (
            <li key={item.label + i}>
              {item.links?.length ? (
                <span
                  title={item.label}
                  onClick={() => setSubTree(item.links)}
                  className="has-children"
                >
                  {item.label}
                </span>
              ) : (
                <Link to={item.url.path} title={item.label} onClick={onClick}>
                  {item.label}
                </Link>
              )}
            </li>
          ))}
      </motion.ul>
      <AnimatePresence>
        {subTree.length && (
          <RecursiveMobileNavigation
            links={subTree}
            onGoBack={() => setSubTree([])}
            onClick={onClick}
            level={level + 1}
          />
        )}
      </AnimatePresence>
    </>
  );
};

MobileNavigation.propTypes = {};

export default MobileNavigation;
