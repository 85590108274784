import React from "react";
import PropTypes from "prop-types";

import {
  LogoFacebook,
  LogoFlickr,
  LogoInstagram,
  LogoLinkedin,
  LogoPinterest,
  LogoTumblr,
  LogoX,
  LogoXing,
  LogoYoutube,
} from "@carbon/icons-react";

export const SocialMedia = ({ content }) => {
  return (
    <div className="col-16 social-media d-flex justify-content-between align-items-center">
      {content.fieldFacebook && (
        <a
          className="facebook"
          target="_blank"
          rel="noopener noreferrer"
          href={content.fieldFacebook.url.path}
        >
          <LogoFacebook size={32} color="white" />
        </a>
      )}
      {content.fieldYoutube && (
        <a
          className="youtube"
          target="_blank"
          rel="noopener noreferrer"
          href={content.fieldYoutube.url.path}
        >
          <LogoYoutube size={32} color="white" />
        </a>
      )}
      {content.fieldFlickr && (
        <a
          className="flickr"
          target="_blank"
          rel="noopener noreferrer"
          href={content.fieldFlickr.url.path}
        >
          <LogoFlickr size={32} color="white" />
        </a>
      )}
      {content.fieldInstagram && (
        <a
          className="instagram"
          target="_blank"
          rel="noopener noreferrer"
          href={content.fieldInstagram.url.path}
        >
          <LogoInstagram size={32} color="white" />
        </a>
      )}
      {content.fieldVimeo && (
        <a
          className="vimeo"
          target="_blank"
          rel="noopener noreferrer"
          href={content.fieldVimeo.url.path}
        >
          <svg
            aria-hidden="true"
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink={"http://www.w3.org/1999/xlink"}
            x="0px"
            y="0px"
            viewBox="0 0 96 96"
            style={{ enableBackground: "new 0 0 96 96" }}
            xmlSpace="preserve"
          >
            <g id="XMLID_1_">
              <path
                id="XMLID_5_"
                className="st0"
                d="M15.7,35.4C22.8,29,29.1,18,36.4,20.3c12.1,3.1,6.9,26.4,13.8,37.2c9.4-5.3,15.6-16.5,13-22.3
                          c-0.6-1.6,0.1-5.7-7.6-2.1c-2,0.9-1.9-2.6-1.6-3.1c7.2-7.9,11.3-11.2,16.2-11c4.1,0.7,11.4,5.3,10.1,16.5
                          C77.6,51.9,53.2,78,45.1,77c-9.3,2.1-14-27.4-21-41.2l-6.4,2.5C15,38.7,15.4,37,15.7,35.4L15.7,35.4z"
                fill="#333333"
              />
            </g>
          </svg>
        </a>
      )}
      {content.fieldLinkedin && (
        <a
          className="linkedin"
          target="_blank"
          rel="noopener noreferrer"
          href={content.fieldLinkedin.url.path}
        >
          <LogoLinkedin size={32} color="white" />
        </a>
      )}
      {content.fieldPinterest && (
        <a
          className="pinterest"
          target="_blank"
          rel="noopener noreferrer"
          href={content.fieldPinterest.url.path}
        >
          <LogoPinterest size={32} color="white" />
        </a>
      )}
      {content.fieldX && (
        <a
          className="x"
          target="_blank"
          rel="noopener noreferrer"
          href={content.fieldX.url.path}
        >
          <LogoX size={32} color="white" />
        </a>
      )}
      {content.fieldXing && (
        <a
          className="xing"
          target="_blank"
          rel="noopener noreferrer"
          href={content.fieldXing.url.path}
        >
          <LogoXing size={32} color="white" />
        </a>
      )}
      {content.fieldTumblr && (
        <a
          className="tumblr"
          target="_blank"
          rel="noopener noreferrer"
          href={content.fieldTumblr.url.path}
        >
          <LogoTumblr size={32} color="white"/>
        </a>
      )}
    </div>
  );
};

SocialMedia.propTypes = {
  content: PropTypes.shape({
    fieldFacebook: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldYoutube: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldFlickr: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldInstagram: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldVimeo: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldLinkedin: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldPinterest: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldX: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldXing: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldTumblr: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
  }),
};
