import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import LogoButton from "../general-components/logo-button";
import useScrollInfo from "@hooks/useScrollInfo";
import classNames from "classnames";
import { AnimatePresence, motion } from "motion/react";
import DesktopNavigation from "./desktop-navigation";
import MobileNavigation from "./mobile-navigation";
import ButtonApotheke from "./button-apotheke";

const Header = () => {
  const scrollInfo = useScrollInfo();
  const [top, setTop] = useState(true);
  const [scrollingUp, setScrollingUp] = useState(false);

  useEffect(() => {
    if (scrollInfo.y > 350) {
      setTop(false);
      document.body.classList.remove("top");
    } else {
      setTop(true);
      document.body.classList.add("top");
    }

    if (scrollInfo.yDir < 0) {
      setScrollingUp(true);
      document.body.classList.add("scrolling-up");
    } else {
      setScrollingUp(false);
      document.body.classList.remove("scrolling-up");
    }
  }, [scrollInfo]);

  return (
    <header
      className={classNames({
        header: true,
        top: top,
        "scrolling-up": scrollingUp,
      })}
    >
      <div className="header-inner">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <LogoButton />
            <DesktopNavigation />
            <div className="d-flex header-actions">
              <ButtonApotheke />
              <MobileNavigation />
            </div>
          </div>
        </div>
      </div>
      {/* 
      <AnimatePresence>
        {scrollInfo?.y > 0 && (
          <motion.div className="header-inner clone" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <div className="container">
              <div className="d-flex justify-content-between align-items-center">
                <LogoButton />
                <DesktopNavigation />
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence> */}
    </header>
  );
};

Header.propTypes = {
  showSearch: PropTypes.bool,
};

export default Header;
