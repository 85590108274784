import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

const Error = ({ error, errorResponse }) => {
  const { status, data } = error;

  // Render message for error responses/exceptions
  if (errorResponse) {
    return (
      <div className="container">
        <div className="error-wrapper text-center">
          <h1>{status}</h1>
          <p className="text-center">
            <FormattedMessage id={`error.${data}`} />
          </p>
        </div>
      </div>
    );
  }

  // Render message for unexpected exceptions
  return (
    <div className="container">
      <div className="error-wrapper text-center">
        <h1>500</h1>
        <p className="text-center">
          <FormattedMessage id="error.general" />
        </p>
        <pre>{error.message}</pre>
      </div>
    </div>
  );
};

Error.propTypes = {
  error: PropTypes.shape({
    data: PropTypes.string,
    status: PropTypes.number,
    message: PropTypes.string,
  }),
  errorResponse: PropTypes.bool,
};

export default Error;
